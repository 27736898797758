import React from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab, Table } from 'react-bootstrap';
import { ExternalLink } from '../../components/links';
import { Markdown } from '../../components/markdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { buildFileUrl } from '../../utils/url-builders';

const packagesPropTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      inclusions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};

const mapInclusions = (packages) => {
  // First build a list of all inclusions from all the packages
  let names = [];

  packages.forEach(({ inclusions }) => {
    names = [...names, ...inclusions];
  });

  // Then build the list of inclusions for display
  const cache = [];
  const output = [];

  for (const name of names) {
    if (cache.indexOf(name) === -1) {
      // Add the inclusion definition
      const inclusion = {
        name,
        count: 0,
        includes: [],
      };

      // Check each package for the inclusion
      packages.forEach(({ inclusions }) => {
        if (inclusions.indexOf(name) > -1) {
          inclusion.count++;
          inclusion.includes.push(true);
        } else {
          inclusion.includes.push(false);
        }
      });

      cache.push(name);
      output.push(inclusion);
    }
  }

  return output.sort((a, b) => {
    // First check the count values (highest first)
    if (a.count > b.count) {
      return -1;
    }

    if (a.count < b.count) {
      return 1;
    }

    // Then sort alphabetically within the count group
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA > nameB) {
      return 1;
    }

    if (nameA < nameB) {
      return -1;
    }

    return 0;
  });
};

class Price extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
  };

  render() {
    const { value } = this.props;
    const price = value.toFixed(2);

    return (
      <th className="h-pricing-price">
        ${price} <small>(+GST)</small>
      </th>
    );
  }
}

class Inclusion extends React.Component {
  static propTypes = {
    includes: PropTypes.arrayOf(PropTypes.bool).isRequired,
  };

  render() {
    const { includes } = this.props;
    return includes.map((include, index) => (
      <td key={index}>{include && <FontAwesomeIcon icon={faCheck} />}</td>
    ));
  }
}

class Matrix extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    document: PropTypes.string,
    ...packagesPropTypes,
  };

  render() {
    const { title, description, document, packages } = this.props;
    const inclusions = mapInclusions(packages);

    // Get the full document url
    let documentUrl = null;
    if (document) {
      documentUrl = buildFileUrl(document);
    }

    return (
      <>
        <div className="h-pricing-description">
          <Markdown content={description} />
        </div>
        {documentUrl && (
          <div className="h-pricing-document">
            <ExternalLink url={documentUrl}>
              Download the {title} price list
            </ExternalLink>
          </div>
        )}
        {!documentUrl && (
          <Table className="h-pricing-table" responsive hover>
            <thead>
              <tr>
                <th></th>
                {packages.map(({ title }, index) => (
                  <th key={index}>{title}</th>
                ))}
              </tr>
              <tr>
                <th></th>
                {packages.map(({ price }, index) => (
                  <Price key={index} value={price} />
                ))}
              </tr>
            </thead>
            <tbody>
              {inclusions.map(({ name, includes }, index) => (
                <tr key={index}>
                  <td className="h-pricing-inclusion">{name}</td>
                  <Inclusion includes={includes} />
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

export default class Pricing extends React.Component {
  static defaultProps = {
    labs: [],
  };

  static propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    labs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        document: PropTypes.string,
        description: PropTypes.string.isRequired,
        ...packagesPropTypes,
      })
    ).isRequired,
  };

  render() {
    const { heading, description, labs } = this.props;
    return (
      <div className="h-pricing">
        <h2>{heading}</h2>
        <Markdown content={description} />
        <Tabs className="h-pricing-tabs" defaultActiveKey="0">
          {labs.map((lab, index) => (
            <Tab
              key={index}
              className="h-pricing-tab"
              eventKey={`${index}`}
              title={lab.title}
            >
              <Matrix {...lab} />
            </Tab>
          ))}
        </Tabs>
      </div>
    );
  }
}
