import React from 'react';

import { BackgroundImage } from '../../components/images';
import { GridSection } from '../../components/sections';

import { buildFileUrl } from '../../utils/url-builders';

export default class HoneLab extends React.Component {
  render() {
    const backgroundImageUrl = buildFileUrl('hone-lab-liquid.png');
    return (
      <div className="h-hone-lab">
        <GridSection columns={2}>
          <div className="h-hone-lab-content">
            <div className="h-hone-lab-coming-soon">Knowledge Now</div>
            <h2>Hone Lab</h2>
            <p>
              Test wine using the latest advances in spectroscopy and machine
              learning. The Hone Lab analyses a 3mL sample of wine and delivers
              results on acids, sugars, alcohol content, plus more, within
              minutes.
            </p>
          </div>
        </GridSection>
        <BackgroundImage
          className="h-hone-lab-image"
          url={backgroundImageUrl}
        />
      </div>
    );
  }
}
