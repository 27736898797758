import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { JoinEventModal } from '../../components/modals';
import { Markdown } from '../../components/markdown';

import { formPropTypes } from '../../utils/prop-types';
import { buildFileUrl } from '../../utils/url-builders';

export default class JoinEvent extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    form: formPropTypes().isRequired,
    onShowModal: PropTypes.func.isRequired,
  };

  constructor(...args) {
    super(...args);

    const showModal = () => {
      this.setState({
        show: true,
      });
    };

    const hideModal = () => {
      this.setState({
        show: false,
      });
    };

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const { heading, form, onShowModal } = this.props;
    const backgroundImage = {
      url: buildFileUrl('hone-liquid-event-bg.png'),
    };

    return (
      <>
        <Section className="h-join-event" backgroundImage={backgroundImage}>
          <h2>Meet with us</h2>
          <Markdown content="Join one of our events near you" />
          <div className="h-join-event-buttons">
            <Button onClick={this.handleShowModal}>Join an event</Button>
            <Button variant="secondary" onClick={onShowModal}>
              Start testing now
            </Button>
          </div>
        </Section>
        <JoinEventModal
          title={heading}
          show={show}
          form={form}
          onHide={this.handleHideModal}
        />
      </>
    );
  }
}
