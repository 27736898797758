import React from 'react';
import PropTypes from 'prop-types';

import { Button, Container } from 'react-bootstrap';
import { SiteConfig } from '../../components/config';
import { ExternalLinkButton } from '../../components/buttons';
import { Section } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { backgroundImagePropTypes } from '../../utils/prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export default class Start extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    backgroundImage: backgroundImagePropTypes().isRequired,
  };

  render() {
    const { heading, content, backgroundImage } = this.props;
    return (
      <SiteConfig>
        {({ signupUrl, createDemoUrl }) => (
          <Section className="h-start" backgroundImage={backgroundImage}>
            <Container className="h-start-container">
              <h2>{heading}</h2>
              <Markdown content={content} />
              <Button href={signupUrl}>Sign up</Button>
              <ExternalLinkButton url={createDemoUrl}>
                <div className="h-start-icon">
                  <FontAwesomeIcon className="h-start-icon" icon={faYoutube} />
                </div>
                Watch Demo
              </ExternalLinkButton>
            </Container>
          </Section>
        )}
      </SiteConfig>
    );
  }
}
