import React from 'react';

import { BackgroundImage } from '../../components/images';
import { LinkButton } from '../../components/buttons';
import { GridSection } from '../../components/sections';

import { buildFileUrl } from '../../utils/url-builders';

export default class HoneLab extends React.Component {
  render() {
    const backgroundImageUrl = buildFileUrl('hone-liquid-about.png');
    return (
      <div className="h-about">
        <GridSection columns={2}>
          <div className="h-about-content">
            <h2>About Us</h2>
            <div>
              <p>
                Hone was established in 2015 to overcome the limitations in food
                and commodity production. The team works with machine learning
                and the latest advances in spectroscopy to provide quick and
                accurate testing solutions.
              </p>
              <p>
                Based in the Hunter region of NSW, Hone was founded on the
                principle of making difficult and time-consuming tasks simple
                and easy. Hone deliver rapid testing solutions to enable quick
                decision making, improving quality and efficiency.
              </p>
            </div>
            <LinkButton to="/company">More about the Hone Team</LinkButton>
          </div>
        </GridSection>
        <BackgroundImage className="h-about-image" url={backgroundImageUrl} />
      </div>
    );
  }
}
