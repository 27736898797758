import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { Image } from '../../components/images';
import { buildFileUrl } from '../../utils/url-builders';

class StepImage extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const { imageUrl, children } = this.props;
    const image = imageUrl ? <Image src={imageUrl} /> : children;

    return (
      <div className="h-how-it-works-cell h-how-it-works-image">{image}</div>
    );
  }
}

class StepText extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;
    return (
      <div className="h-how-it-works-cell h-how-it-works-text">{children}</div>
    );
  }
}

export default class HowItWorks extends React.Component {
  render() {
    const titleImageUrl = buildFileUrl('how-it-works-icon.svg');
    const mobileImageUrl = buildFileUrl('mobile-icon.svg');
    const grainImageUrl = buildFileUrl('wheat-icon.svg');
    const leafImageUrl = buildFileUrl('leaf-icon.svg');
    const hlrImageUrl = buildFileUrl('hlr-icon.svg');
    const dataImageUrl = buildFileUrl('data-processing-icon.svg');
    const devicesImageUrl = buildFileUrl('devices-icon.svg');
    const arrowImageUrl = buildFileUrl('how-it-works-arrow.png');

    const images = (
      <>
        <StepImage imageUrl={mobileImageUrl}></StepImage>
        <StepImage>
          <Image className="h-how-it-works-grain" src={grainImageUrl} />
          <Image className="h-how-it-works-leaf" src={leafImageUrl} />
        </StepImage>
        <StepImage imageUrl={hlrImageUrl}></StepImage>
        <StepImage imageUrl={dataImageUrl}></StepImage>
        <StepImage imageUrl={devicesImageUrl}></StepImage>
        <div className="h-how-it-works-arrow">
          <Image src={arrowImageUrl} />
        </div>
      </>
    );

    const text = (
      <>
        <StepText>1. Pair Hone Lab With The Mobile App</StepText>
        <StepText>2. Insert Sample</StepText>
        <StepText>3. Complete Scans</StepText>
        <StepText>4. Spectral Data Processed Online</StepText>
        <StepText>5. See Result On The App</StepText>
      </>
    );

    return (
      <Section className="h-how-it-works">
        <Container className="h-how-it-works-title">
          <Image src={titleImageUrl} />
          <h2>How It Works</h2>
        </Container>
        <div className="h-how-it-works-vertical">
          <Container className="h-how-it-works-row">
            <div className="h-how-it-works-images">{images}</div>
            <div className="h-how-it-works-texts">{text}</div>
          </Container>
        </div>
        <div className="h-how-it-works-horizontal">
          <div className="h-how-it-works-images">
            <div className="h-how-it-works-bg"></div>
            <Container className="h-how-it-works-row">{images}</Container>
          </div>
          <Container className="h-how-it-works-row">{text}</Container>
        </div>
      </Section>
    );
  }
}
