import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import { Row, Col } from 'react-bootstrap';
import { Image } from '../../components/images';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { buildFileUrl } from '../../utils/url-builders';

class Feature extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
  };

  render() {
    const { title, image, children } = this.props;
    return (
      <>
        <div className="h-feature">
          <Image src={image} description={title} />
          <h2>{title}</h2>
          <Markdown className="h-feature-description" content={children} />
        </div>
      </>
    );
  }
}

export default class Testing extends React.Component {
  render() {
    const portableTestingImageUrl = buildFileUrl('on-farm-icon.svg');
    const reducedCostImageUrl = buildFileUrl('why-no-forms.svg');
    const instantResultsImageUrl = buildFileUrl('why-checklist.svg');

    return (
      <div className="h-hone-carbon-features">
        <GridSection columns={3}>
          <Feature title="Portable Testing" image={portableTestingImageUrl}>
            Measure soil carbon in-field, without the need for a commercial
            laboratory.
          </Feature>
          <Feature title="Reduced Cost" image={reducedCostImageUrl}>
            Save significant time and money on soil sampling and analysis.
          </Feature>
          <Feature title="Instant Results" image={instantResultsImageUrl}>
            Receive soil carbon test results in minutes instead of weeks.
          </Feature>
        </GridSection>
        <Row className="h-video">
          <Col xs={12} lg={6}>
            <YouTube
              className="h-video-frame"
              containerClassName="h-video-container"
              videoId="1114g2P0FCg"
            />
          </Col>
        </Row>
      </div>
    );
  }
}
