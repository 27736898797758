import Stats from './stats';
import Divisions from './divisions';

import HoneAgCustomers from './hone-ag/customers';
import HoneAgHoneCommunity from './hone-ag/hone-community';
import HoneAgHoneLab from './hone-ag/hone-lab';
import HoneAgJoinEvent from './hone-ag/join-event';
import HoneAgPlatform from './hone-ag/platform';
import HoneAgTesting from './hone-ag/testing';

import HoneCreateBottleneck from './hone-create/bottleneck';
import HoneCreateFeatures from './hone-create/features';
import HoneCreateIntroduction from './hone-create/introduction';
import HoneCreateModelling from './hone-create/modelling';
import HoneCreatePartners from './hone-create/partners';
import HoneCreateSolution from './hone-create/solution';
import HoneCreateStart from './hone-create/start';

import HoneCarbonFeatures from './hone-carbon/features';
import HoneCarbonTechnology from './hone-carbon/technology';
import HoneCarbonContact from './hone-carbon/contact';

import HoneLiquidHoneLab from './hone-liquid/hone-lab';
import HoneLiquidTesting from './hone-liquid/testing';
import HoneLiquidTechnology from './hone-liquid/technology';
import HoneLiquidPartnerships from './hone-liquid/partnerships';
import HoneLiquidAbout from './hone-liquid/about';
import HoneLiquidPsf from './hone-liquid/psf';
import HoneLiquidJoinEvent from './hone-liquid/join-event';

import HoneTestJoin from './hone-test/join';
import HoneTestProcess from './hone-test/process';
import HoneTestWhy from './hone-test/why';
import HoneTestPricing from './hone-test/pricing';
import HoneTestDownload from './hone-test/download';
import HoneTestDownloadForm from './hone-test/download-form';

import HoneLabLabLinks from './hone-lab/lab-links';
import HoneLabKnowledge from './hone-lab/knowledge';
import HoneLabCharacteristics from './hone-lab/characteristics';
import HoneLabFarm from './hone-lab/farm';
import HoneLabHowItWorks from './hone-lab/how-it-works';
import HoneLabTechnology from './hone-lab/technology';
import HoneLabSpecifications from './hone-lab/specifications';
import HoneLabStockist from './hone-lab/stockist';
import HoneLabContact from './hone-lab/contact';

import FeaturedIn from './company/featured-in';
import Team from './company/team';

export default class Content {
  static HoneAg = {
    HoneLab: HoneAgHoneLab,
    Testing: HoneAgTesting,
    Platform: HoneAgPlatform,
    Divisions,
    HoneCommunity: HoneAgHoneCommunity,
    Customers: HoneAgCustomers,
    Stats,
    JoinEvent: HoneAgJoinEvent,
  };

  static HoneCreate = {
    Introduction: HoneCreateIntroduction,
    Bottleneck: HoneCreateBottleneck,
    Solution: HoneCreateSolution,
    Modelling: HoneCreateModelling,
    Start: HoneCreateStart,
    Features: HoneCreateFeatures,
    Partners: HoneCreatePartners,
    Divisions,
  };

  static HoneCarbon = {
    Features: HoneCarbonFeatures,
    Technology: HoneCarbonTechnology,
    Divisions,
    Contact: HoneCarbonContact,
  };

  static HoneLiquid = {
    HoneLab: HoneLiquidHoneLab,
    Testing: HoneLiquidTesting,
    Technology: HoneLiquidTechnology,
    Partnerships: HoneLiquidPartnerships,
    Divisions,
    About: HoneLiquidAbout,
    Psf: HoneLiquidPsf,
    JoinEvent: HoneLiquidJoinEvent,
  };

  static HoneTest = {
    Introduction: HoneTestJoin,
    Process: HoneTestProcess,
    Why: HoneTestWhy,
    Pricing: HoneTestPricing,
    Download: HoneTestDownload,
    DownloadForm: HoneTestDownloadForm,
  };

  static HoneLab = {
    LabLinks: HoneLabLabLinks,
    Knowledge: HoneLabKnowledge,
    Characteristics: HoneLabCharacteristics,
    Farm: HoneLabFarm,
    HowItWorks: HoneLabHowItWorks,
    Technology: HoneLabTechnology,
    Specifications: HoneLabSpecifications,
    Stockist: HoneLabStockist,
    Contact: HoneLabContact,
  };

  static Company = {
    Stats,
    Team,
    FeaturedIn,
  };
}
