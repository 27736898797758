import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import { Image } from '../../components/images';
import { Section } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { imagePropTypes } from '../../utils/prop-types';

const stepPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: imagePropTypes().isRequired,
};

class Step extends React.Component {
  static propTypes = {
    ordinal: PropTypes.number.isRequired,
    ...stepPropTypes,
  };

  render() {
    const { ordinal, title, description, image } = this.props;
    return (
      <div className="h-steps-content">
        <div className="h-steps-image">
          <Image description={title} {...image} />
        </div>
        <div className="h-steps-description">
          <h3>
            {ordinal}. {title}
          </h3>
          <Markdown content={description} />
        </div>
      </div>
    );
  }
}

export default class Modelling extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...stepPropTypes,
      })
    ).isRequired,
  };

  render() {
    const { heading, items } = this.props;
    return (
      <Section className="h-steps">
        <Container className="h-steps-container">
          <h2>{heading}</h2>
          {items.map((props, index) => (
            <Step key={index} ordinal={index + 1} {...props} />
          ))}
        </Container>
      </Section>
    );
  }
}
