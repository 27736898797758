import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-bootstrap';
import { SiteConfig } from '../../components/config';
import { Image, BackgroundImage } from '../../components/images';
import { Section } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import {
  imagePropTypes,
  backgroundImagePropTypes,
} from '../../utils/prop-types';

const partnerPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: imagePropTypes().isRequired,
};

class Partner extends React.Component {
  static propTypes = {
    ...partnerPropTypes,
    email: PropTypes.string.isRequired,
  };

  render() {
    const { title, description, link, image, email } = this.props;
    return (
      <div className="h-hone-community-partner">
        <div className="h-hone-community-icon">
          <Image description={title} {...image} />
        </div>
        <div className="h-hone-community-description">
          <h3>{title}</h3>
          <Markdown content={description} />
          <div className="h-hone-community-link">
            <a href={`mailto:${email}`}>{link}</a>
          </div>
        </div>
      </div>
    );
  }
}

export default class HoneCommunity extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...partnerPropTypes,
      })
    ).isRequired,
    image: backgroundImagePropTypes().isRequired,
  };

  render() {
    const { heading, items, image } = this.props;
    return (
      <SiteConfig>
        {({ email }) => (
          <div className="h-hone-community">
            <Section>
              <Container>
                <Row>
                  <Col className="h-hone-community-content" lg={{ offset: 6 }}>
                    <h2>{heading}</h2>
                    {items.map((props, index) => (
                      <Partner key={index} email={email} {...props} />
                    ))}
                  </Col>
                </Row>
              </Container>
            </Section>
            <BackgroundImage className="h-hone-community-image" {...image} />
          </div>
        )}
      </SiteConfig>
    );
  }
}
