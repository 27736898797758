import React from 'react';

import { SiteConfig } from '../config';
import FormModal from './form-modal';
import { formPropTypes } from '../../utils/prop-types';

export default class HlrPreOrderModal extends React.Component {
  static propTypes = {
    form: formPropTypes().isRequired,
  };

  render() {
    return (
      <SiteConfig>
        {({ contactFormUrl }) => (
          <FormModal
            className="h-hlr-pre-order-modal"
            title="Hone Lab Red Pre-order Form"
            controls={[
              new FormModal.Form.FullName('Name'),
              new FormModal.Form.Email('Email'),
              new FormModal.Form.PhoneNumber('PhoneNumber'),
            ]}
            submitTo={`${contactFormUrl}?subject=Hone Lab Pre-order`}
            {...this.props}
            {...this.props.form}
          />
        )}
      </SiteConfig>
    );
  }
}
