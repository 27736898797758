import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'gatsby';

export default class LinkButton extends React.Component {
  static defaultProps = {
    variant: 'primary',
  };

  static propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { className, variant, children, ...props } = this.props;
    return (
      <Link
        className={classNames('btn', `btn-${variant}`, className)}
        {...props}
      >
        {children}
      </Link>
    );
  }
}
