import React from 'react';
import PropTypes from 'prop-types';

import { BackgroundImage } from '../../components/images';
import { GridSection } from '../../components/sections';
import { LinkButton } from '../../components/buttons';
import { Markdown } from '../../components/markdown';

import { backgroundImagePropTypes } from '../../utils/prop-types';

export default class HoneLab extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: backgroundImagePropTypes().isRequired,
  };

  render() {
    const { heading, content, image } = this.props;
    return (
      <div className="h-hone-lab">
        <GridSection columns={2}>
          <div className="h-hone-lab-content">
            <div className="h-hone-lab-coming-soon">Knowledge Now</div>
            <h2>{heading}</h2>
            <Markdown content={content} />
            <LinkButton variant="secondary" to="/hone-lab">
              More Info
            </LinkButton>
          </div>
        </GridSection>
        <BackgroundImage className="h-hone-lab-image" {...image} />
      </div>
    );
  }
}
