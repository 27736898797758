import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import { Container, Row, Col } from 'react-bootstrap';
import { BackgroundImage } from '../../components/images';
import { Section } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { backgroundImagePropTypes } from '../../utils/prop-types';

export default class Customers extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    videoIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    backgroundImage: backgroundImagePropTypes().isRequired,
  };

  render() {
    const { heading, content, videoIDs, backgroundImage } = this.props;
    return (
      <Section className="h-customer">
        <BackgroundImage
          className="h-customer-background"
          {...backgroundImage}
        />
        <Container className="h-customer-container">
          <h2>{heading}</h2>
          <Markdown content={content} />
          <Row>
            {videoIDs.map((id) => (
              <Col key={id} className="h-customer-video" xs={12} lg={6}>
                <YouTube
                  className="h-customer-video-frame"
                  containerClassName="h-customer-video-container"
                  videoId={id}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    );
  }
}
