import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import { Container } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { ExternalLinkButton } from '../../components/buttons';
import { Image, BackgroundImage } from '../../components/images';
import { buildFileUrl } from '../../utils/url-builders';
import HlrPreOrderModal from '../../components/modals/hlr-pre-order-modal';

class SampleType extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
  };

  render() {
    const { name, imageUrl, children } = this.props;
    return (
      <div className="h-sample-type">
        <div className="h-avatar">
          <Image src={imageUrl} />
          <div className="h-name">{name}</div>
        </div>
        <div className="h-description">{children}</div>
      </div>
    );
  }
}

export default class Knowledge extends React.Component {
  constructor(...args) {
    super(...args);

    const showModal = () => {
      this.setState({
        show: true,
      });
    };

    const hideModal = () => {
      this.setState({
        show: false,
      });
    };

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const backgroundImageUrl = buildFileUrl('hlr-hero-bg.png');
    const logoImageUrl = buildFileUrl('hone-logo-symbol.svg');
    const heroImageUrl = buildFileUrl('hlr-hero.png');
    const wheatImageUrl = buildFileUrl('wheat-icon.svg');
    const barleyImageUrl = buildFileUrl('barley-icon.svg');
    const canolaImageUrl = buildFileUrl('canola-icon.svg');

    return (
      <BackgroundImage url={backgroundImageUrl}>
        <Section className="h-hlr-knowledge">
          <Container className="h-container">
            <div className="h-logo">
              <Image src={logoImageUrl} />
            </div>
            <div className="h-text">
              <span className="h-text-red">HONE</span>
              <span className="h-text-black">LAB</span>
            </div>
            <div className="h-knowledge-phrase h-text-black">Knowledge Now</div>
            <div className="h-hlr-device">
              <Image src={heroImageUrl} />
            </div>
            <div className="h-hlr-analyser">On-farm Grain Analyser</div>
            <div className="h-sample-types">
              <SampleType name="Wheat" imageUrl={wheatImageUrl}>
                moisture and protein
              </SampleType>
              <SampleType name="Barley" imageUrl={barleyImageUrl}>
                moisture and protein
              </SampleType>
              <SampleType name="Canola" imageUrl={canolaImageUrl}>
                oil, moisture and protein
              </SampleType>
            </div>
            <div>
              <ExternalLinkButton url="https://www.tryhone.com/">
                PRE-ORDER NOW
              </ExternalLinkButton>
            </div>
            <div className="h-limited-stock">
              <p>Limited Stock</p>
            </div>
            <div className="h-videos">
              <YouTube
                className="h-video-frame"
                containerClassName="h-video-container"
                videoId="IEr5WfNLhdI"
              />
              <YouTube
                className="h-video-frame"
                containerClassName="h-video-container"
                videoId="hsqIIrVOQzo"
              />
            </div>
          </Container>
          <HlrPreOrderModal
            show={show}
            onHide={this.handleHideModal}
            form={{
              description:
                '<p>To become one of the first Hone Lab users for grain testing, please provide your information below and a Hone representative will be in contact to confirm your order.</p>',
              buttonText: 'Pre-order Now',
              submittedMessage:
                'Thank you, your details have been sent through to us and a Hone representative will be in contact.',
            }}
          />
        </Section>
      </BackgroundImage>
    );
  }
}
