import React from 'react';
import PropTypes from 'prop-types';

import { Markdown } from '../../components/markdown';

export default class Join extends React.Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
  };

  render() {
    const { content } = this.props;
    return (
      <div className="h-join">
        <Markdown content={content} />
      </div>
    );
  }
}
