import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../components/images';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { imagePropTypes } from '../../utils/prop-types';

const memberPropTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  qualifications: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string.isRequired,
  photo: imagePropTypes().isRequired,
};

class Member extends React.Component {
  static propTypes = {
    ...memberPropTypes,
  };

  render() {
    const { name, title, qualifications, description, photo } = this.props;
    return (
      <div>
        <Image description={name} {...photo} />
        <h2>{name}</h2>
        <h3>{title}</h3>
        {qualifications && (
          <div className="h-team-qualification">
            {qualifications.map((qualification, index) => (
              <div key={index}>{qualification}</div>
            ))}
          </div>
        )}
        <Markdown content={description} />
      </div>
    );
  }
}

export default class Team extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...memberPropTypes,
      })
    ).isRequired,
  };

  render() {
    const { items } = this.props;
    return (
      <GridSection className="h-team" columns={3}>
        {items.map((props, index) => (
          <Member key={index} {...props} />
        ))}
      </GridSection>
    );
  }
}
