import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { JoinEventModal } from '../../components/modals';
import { LinkButton } from '../../components/buttons';
import { Markdown } from '../../components/markdown';

import {
  backgroundImagePropTypes,
  formPropTypes,
} from '../../utils/prop-types';

export default class JoinEvent extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    backgroundImage: backgroundImagePropTypes().isRequired,
    form: formPropTypes().isRequired,
  };

  constructor(...args) {
    super(...args);

    const showModal = () => {
      this.setState({
        show: true,
      });
    };

    const hideModal = () => {
      this.setState({
        show: false,
      });
    };

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const { heading, content, backgroundImage, form } = this.props;

    return (
      <>
        <Section className="h-join-event" backgroundImage={backgroundImage}>
          <h2>{heading}</h2>
          <Markdown content={content} />
          <div className="h-join-event-buttons">
            <Button onClick={this.handleShowModal}>Join an event</Button>
            <LinkButton variant="secondary" to="/hone-test">
              Start testing now
            </LinkButton>
          </div>
        </Section>
        <JoinEventModal
          title={heading}
          show={show}
          form={form}
          onHide={this.handleHideModal}
        />
      </>
    );
  }
}
