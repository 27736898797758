import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../components/images';
import { ExternalLink } from '../../components/links';
import { GridSection } from '../../components/sections';

import { imagePropTypes } from '../../utils/prop-types';

const articlePropTypes = {
  title: PropTypes.string.isRequired,
  logo: imagePropTypes().isRequired,
  url: PropTypes.string.isRequired,
};

class Article extends React.Component {
  static propTypes = {
    ...articlePropTypes,
  };

  render() {
    const { title, logo, url } = this.props;
    return (
      <div>
        <ExternalLink url={url}>
          <Image description={title} {...logo} />
        </ExternalLink>
      </div>
    );
  }
}

export default class FeaturedIn extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...articlePropTypes,
      })
    ).isRequired,
  };

  render() {
    const { heading, items } = this.props;
    return (
      <div className="h-featured-in">
        <h2>{heading}</h2>
        <GridSection columns={3}>
          {items.map((props, index) => (
            <Article key={index} {...props} />
          ))}
        </GridSection>
      </div>
    );
  }
}
