import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import { Image } from '../../components/images';
import { Section } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { imagePropTypes } from '../../utils/prop-types';

const componentPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: imagePropTypes().isRequired,
};

class Component extends React.Component {
  static propTypes = {
    ...componentPropTypes,
  };

  render() {
    const { title, description, image } = this.props;
    return (
      <div className="h-platform-component">
        <Image description={title} {...image} />
        <h3>{title}</h3>
        <Markdown content={description} />
      </div>
    );
  }
}

export default class Platform extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...componentPropTypes,
      })
    ).isRequired,
    image: imagePropTypes().isRequired,
  };

  render() {
    const { heading, items, image } = this.props;
    return (
      <Section className="h-platform">
        <h2>{heading}</h2>
        <Container className="h-platform-image">
          <Image description={heading} {...image} />
        </Container>
        <Container className="h-platform-container">
          {items.map((props, index) => (
            <Component key={index} {...props} />
          ))}
        </Container>
      </Section>
    );
  }
}
