import React from 'react';
import PropTypes from 'prop-types';

import { Carousel } from 'react-bootstrap';
import { Image } from '../../components/images';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { imagePropTypes } from '../../utils/prop-types';

export default class Process extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: imagePropTypes().isRequired,
      })
    ).isRequired,
  };

  render() {
    const { heading, items } = this.props;
    return (
      <div className="h-process">
        <h2>{heading}</h2>
        <Carousel
          className="h-process-carousel"
          interval={null}
          indicators={false}
          prevIcon={
            <div className="h-process-carousel-nav">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          }
          nextIcon={
            <div className="h-process-carousel-nav">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          }
        >
          {items.map(({ title, image }, index) => (
            <Carousel.Item key={index}>
              <Image description={title} {...image} />
              <Carousel.Caption className="h-process-carousel-caption">
                {index + 1}. {title}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
}
