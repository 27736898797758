import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import { SiteConfig } from '../../components/config';
import { GridSection } from '../../components/sections';
import { Image } from '../../components/images';
import { ExternalLink } from '../../components/links';
import { buildFileUrl } from '../../utils/url-builders';

export default class LabLinks extends React.Component {
  static propTypes = {
    onShowModal: PropTypes.func.isRequired,
  };

  render() {
    const { onShowModal } = this.props;
    const onFarmImageUrl = buildFileUrl('on-farm-icon.svg');
    const howItWorksImageUrl = buildFileUrl('how-it-works-icon.svg');
    const downloadImageUrl = buildFileUrl('download-icon.svg');
    const moreImageUrl = buildFileUrl('hlr-more.svg');

    return (
      <SiteConfig>
        {({ hlrBrochureUrl }) => (
          <GridSection className="h-hlr-links" columns={2}>
            <div className="h-avatar-group">
              <Link className="h-avatar-inner" to="#farm" data-smooth-scroll>
                <div className="h-avatar h-farm-icon">
                  <Image src={onFarmImageUrl} />
                </div>
                <div className="h-avatar-text">On The Farm</div>
              </Link>
              <Link to="#how-it-works" data-smooth-scroll>
                <div className="h-avatar-inner">
                  <div className="h-avatar">
                    <Image src={howItWorksImageUrl} />
                  </div>
                  <div className="h-avatar-text">How It Works</div>
                </div>
              </Link>
            </div>
            <div className="h-avatar-group">
              <ExternalLink className="h-avatar-inner" url={hlrBrochureUrl}>
                <div className="h-avatar">
                  <Image src={downloadImageUrl} />
                </div>
                <div className="h-avatar-text">Download Brochure</div>
              </ExternalLink>
              <div className="h-avatar-inner" onClick={onShowModal}>
                <div className="h-avatar">
                  <Image src={moreImageUrl} />
                </div>
                <div className="h-avatar-text">Tell Me More</div>
              </div>
            </div>
          </GridSection>
        )}
      </SiteConfig>
    );
  }
}
