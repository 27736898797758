import React from 'react';

import { Container } from 'react-bootstrap';
import { Image } from '../../components/images';
import { Section } from '../../components/sections';

import { buildFileUrl } from '../../utils/url-builders';

export default class Technology extends React.Component {
  render() {
    const logo = buildFileUrl('nsw-logo.png');
    return (
      <Section className="h-psf">
        <Container>
          <p>
            Hone Liquid has been recognised as a leading innovator, delivering
            industry solutions beneficial to Australia and the state of New
            South Wales. Hone enjoys support from the state government to aid
            research and development with Hone Lab.
          </p>
          <div className="h-psf-logo">
            <Image src={logo} srcSet={logo} />
          </div>
          <p>
            The Physical Sciences Fund (PSF) is a NSW Government competitive
            development and commercialisation program run by the Office of the
            NSW Chief Scientist & Engineer. The PSF aims to deliver significant
            social, economic and environmental benefits to NSW by providing
            financial support for the development of new and innovative devices
            and systems across the branches of the physical sciences and
            engineering, including physics, chemistry, astronomy and the earth
            sciences.
          </p>
        </Container>
      </Section>
    );
  }
}
