import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { Markdown } from '../../components/markdown';

export default class Bottleneck extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  render() {
    const { heading, content } = this.props;
    return (
      <Section className="h-bottleneck">
        <Container>
          <h2>{heading}</h2>
          <Markdown content={content} />
        </Container>
      </Section>
    );
  }
}
