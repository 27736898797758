import React from 'react';
import PropTypes from 'prop-types';

import { AppLinks } from '../../components/links';
import { Markdown } from '../../components/markdown';

export default class Download extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  render() {
    const { heading, content } = this.props;
    return (
      <div className="h-download">
        <h2>{heading}</h2>
        <Markdown content={content} />
        <AppLinks />
      </div>
    );
  }
}
