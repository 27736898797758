import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BackgroundImage } from '../images';

import { backgroundImagePropTypes } from '../../utils/prop-types';

export default class Section extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    backgroundImage: backgroundImagePropTypes(),
    children: PropTypes.node.isRequired,
  };

  render() {
    const { className, backgroundImage, children } = this.props;
    const classes = classNames('h-section', className);

    if (backgroundImage) {
      return (
        <BackgroundImage as="section" className={classes} {...backgroundImage}>
          {children}
        </BackgroundImage>
      );
    }

    return <section className={classes}>{children}</section>;
  }
}
