import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import { Image } from '../components/images';
import { Section } from '../components/sections';
import { ExternalLinkButton } from '../components/buttons';

import { buildFileUrl } from '../utils/url-builders';
import { backgroundImagePropTypes } from '../utils/prop-types';

export default class Divisions extends React.Component {
  static propTypes = {
    backgroundImage: backgroundImagePropTypes(),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { backgroundImage, items, children } = this.props;
    const image = buildFileUrl('hone-logo.svg');

    return (
      <Section className="h-divisions" backgroundImage={backgroundImage}>
        <Container className="h-divisions-container">
          <Image src={image} srcSet={image} />
          <div className="h-divisions-buttons">
            {items.map(({ label, url }) => (
              <ExternalLinkButton key={url} url={url}>
                {label}
              </ExternalLinkButton>
            ))}
          </div>
          <h3>Knowledge Now</h3>
          <p>{children}</p>
        </Container>
      </Section>
    );
  }
}
