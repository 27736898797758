import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../components/images';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { imagePropTypes } from '../../utils/prop-types';

export default class Solution extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: imagePropTypes().isRequired,
  };

  render() {
    const { heading, content, image } = this.props;
    return (
      <GridSection className="h-solution" columns={2}>
        <div>
          <h2>{heading}</h2>
          <Markdown content={content} />
        </div>
        <div>
          <Image description={heading} {...image} />
        </div>
      </GridSection>
    );
  }
}
