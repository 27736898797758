import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { Image } from '../../components/images';
import { buildFileUrl } from '../../utils/url-builders';

const stockistDetails = [
  {
    id: 'WA',
    state: 'Western Australia',
    stockists: [
      {
        name: 'Farm and General',
        address: '46 Norseman Rd, Chadwick WA',
        phoneNumber: '08 9072 0888',
      },
      {
        name: 'Kojonup Ag Supplies',
        address: '2 Thornbury Cl, Kojonup WA',
        phoneNumber: '08 9831 1022',
      },
    ],
  },
  {
    id: 'SA',
    state: 'South Australia',
    stockists: [
      {
        name: 'Grower’s Supplies',
        address: '48 Hawker St, Burton SA',
        phoneNumber: '08 8280 3888',
      },
      {
        name: 'J & D Southwood',
        address: '19-21 Elizabeth St. Maitland SA',
        phoneNumber: '08 8832 2346',
      },
      {
        name: 'Bawdens Rural Supplies',
        address: '26 Lipson Rd, Tumby Bay SA',
        phoneNumber: '08 8688 2388',
      },
    ],
  },
  {
    id: 'NSW',
    state: 'New South Wales',
    stockists: [
      {
        name: 'AMPS Moree',
        address: '70 Industrial Dr, Moree NSW',
        phoneNumber: '02 6752 9001',
      },
      {
        name: 'AMPS Waverley',
        address: '690 Waverley Rd, Caroona NSW',
        phoneNumber: '02 6747 4988',
      },
      {
        name: 'Baker Ag Advantage',
        address: '15 Riley St, Forbes NSW',
        phoneNumber: '0428 635 556',
      },
    ],
  },
  {
    id: 'VIC',
    state: 'Victoria',
    stockists: [
      {
        name: 'Driscoll Ag',
        address: '43 Maryborough-Dunolly Rd, Maryborough Vic',
        phoneNumber: '03 5461 1922',
      },
      {
        name: 'Gorst Rural Supplies',
        address: 'Weighbridge Pl, Lake Bolac Vic',
        phoneNumber: '03 5350 2440',
      },
    ],
  },
];

class StockistGroup extends React.Component {
  static propTypes = {
    stateId: PropTypes.string.isRequired,
  };

  render() {
    const { stateId } = this.props;

    const details = stockistDetails.find(({ id }) => id === stateId);

    if (!details) {
      return null;
    }
    const { state, stockists = [] } = details;

    if (!state || !stockists) {
      return null;
    }

    return (
      <div className="h-stockist-group">
        <div className="h-stockist-state">{state}</div>

        {stockists.map(({ name, address, phoneNumber }, index) => (
          <StockistItem
            key={index}
            name={name}
            address={address}
            phoneNumber={phoneNumber}
          />
        ))}
      </div>
    );
  }
}

class StockistItem extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  };

  render() {
    const { name, address, phoneNumber } = this.props;
    return (
      <div className="h-stockist-item">
        <div className="h-stockist-name">{name}</div>
        <div>{address}</div>
        <div>Ph. {phoneNumber}</div>
      </div>
    );
  }
}

export default class Stockist extends React.Component {
  render() {
    const mapImageUrl = buildFileUrl('stockists-map.png');

    return (
      <Section>
        <Container className="h-hlr-stockist">
          <div>
            <h2>Find Your Stockist, Now.</h2>
            <div>
              Hone Lab is available through our network of stockists. Please
              contact them directly to order your Hone Lab today.
            </div>
            <div className="h-limited">Limited Stock</div>
          </div>
          <Row className="h-hlr-stockist-details-map">
            <Col lg={3}>
              <StockistGroup stateId="WA" />
              <StockistGroup stateId="SA" />
            </Col>
            <Col className="h-hlr-stockist-map" lg={6}>
              <Image src={mapImageUrl} />
            </Col>
            <Col lg={3}>
              <StockistGroup stateId="QLD" />
              <StockistGroup stateId="NSW" />
              <StockistGroup stateId="VIC" />
            </Col>
          </Row>
          <Row className="h-hlr-stockist-details-list">
            <Col sm={12} md={6}>
              <StockistGroup stateId="NSW" />
              <StockistGroup stateId="QLD" />
              <StockistGroup stateId="SA" />
            </Col>
            <Col sm={12} md={6}>
              <StockistGroup stateId="VIC" />
              <StockistGroup stateId="WA" />
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }
}
