import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../components/images';
import { GridSection } from '../../components/sections';
import { TestingModal } from '../../components/modals';
import { Markdown } from '../../components/markdown';

import {
  imagePropTypes,
  backgroundImagePropTypes,
} from '../../utils/prop-types';

const materialPropTypes = {
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: imagePropTypes().isRequired,
  animation: imagePropTypes().isRequired,
};

class Material extends React.Component {
  static propTypes = {
    ...materialPropTypes,
  };

  constructor(...args) {
    super(...args);

    const showModal = () => {
      this.setState({
        show: true,
      });
    };

    const hideModal = () => {
      this.setState({
        show: false,
      });
    };

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const { type, description, image, animation } = this.props;
    const title = `Test your ${type.toLowerCase()}`;

    return (
      <>
        <div className="h-testing-material">
          <Image description={title} {...image} />
          <h2>{title}</h2>
          <Markdown className="h-testing-description" content={description} />
        </div>
        <TestingModal
          title={title}
          image={animation}
          show={show}
          onHide={this.handleHideModal}
        />
      </>
    );
  }
}

export default class Testing extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...materialPropTypes,
      })
    ).isRequired,
    backgroundImage: backgroundImagePropTypes().isRequired,
  };

  render() {
    const { items, backgroundImage } = this.props;
    return (
      <GridSection
        className="h-testing"
        backgroundImage={backgroundImage}
        columns={3}
      >
        {items.map((props, index) => (
          <Material key={index} {...props} />
        ))}
      </GridSection>
    );
  }
}
