import React from 'react';

import { SiteConfig } from '../../components/config';
import { Form } from '../../components/forms';

import { formPropTypes } from '../../utils/prop-types';

export default class DownloadForm extends React.Component {
  static propTypes = {
    form: formPropTypes().isRequired,
  };

  render() {
    const { description, buttonText, submittedMessage } = this.props.form;
    return (
      <SiteConfig>
        {({ downloadFormUrl }) => (
          <div className="h-download-form">
            <h2>{description}</h2>
            <Form
              controls={[new Form.FullName('Name'), new Form.Email('Email')]}
              buttonText={buttonText}
              submitTo={downloadFormUrl}
              submittedMessage={submittedMessage}
            />
          </div>
        )}
      </SiteConfig>
    );
  }
}
