import React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from 'gatsby';
import { GridSection } from '../components/sections';

class Stat extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  };

  render() {
    const { name, value } = this.props;

    // Convert the value to the locale number string (to include separators)
    let stat = value.toLocaleString();

    // For IE, ensure the decimal places are removed
    const pos = stat.indexOf('.');

    if (pos > -1) {
      stat = stat.substring(0, pos);
    }

    return (
      <div>
        <div className="h-stats-value">{stat}</div>
        <h2>{name}</h2>
      </div>
    );
  }
}

export default class Stats extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query StatsQuery {
            stats: apiStats {
              Analyses
              Requests
              Users
            }
          }
        `}
        render={({ stats }) => (
          <GridSection className="h-stats" columns={3}>
            <Stat name="Models" value={stats.Analyses} />
            <Stat name="Tests" value={stats.Requests} />
            <Stat name="Users" value={stats.Users} />
          </GridSection>
        )}
      />
    );
  }
}
