import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { CreateModal } from '../../components/modals';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVials,
  faPeopleArrows,
  faUsersCog,
  faToolbox,
} from '@fortawesome/free-solid-svg-icons';

const items = [
  {
    title: 'Lab Partners',
    description:
      'We partner with accredited labs that can provide high quality test results to our users.',
    icon: faVials,
    link: 'Want to transform your lab?',
  },
  {
    title: 'Distributors',
    description:
      'We partner with hardware and software distributors worldwide who want to extend market reach using our platform.',
    icon: faPeopleArrows,
    link: 'Want to grow your market?',
  },
  {
    title: 'Development Partners',
    description:
      'Our development partners create sophisticated models that anyone can use to make predictions in-field.',
    icon: faUsersCog,
    link: 'Want to develop and distribute your own models?',
  },
  {
    title: 'Instrument Manufacturers',
    description:
      'Our platform is hardware agnostic. We welcome innovative manufacturers to join our community offering a fully integrated solution.',
    icon: faToolbox,
    link: 'Want to create new opportunities for your instrument?',
  },
];

class Reason extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { title, description, icon, link, onClick } = this.props;
    return (
      <div className="h-partner">
        <div className="h-partner-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="h-partner-description">
          <h3>{title}</h3>
          <Markdown content={description} />
          <div className="h-partner-link">
            <Button variant="link" onClick={() => onClick(title)}>
              {link}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default class partners extends React.Component {
  constructor(...args) {
    super(...args);

    const showModal = (title) =>
      this.setState({
        modalTitle: title,
      });

    const hideModal = () =>
      this.setState({
        modalTitle: '',
      });

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      modalTitle: '',
    };
  }

  render() {
    const { modalTitle } = this.state;
    return (
      <div className="h-partners">
        <h2>Hone Create Partners</h2>
        <GridSection columns={2}>
          {items.map((props, index) => (
            <Reason key={index} onClick={this.handleShowModal} {...props} />
          ))}
        </GridSection>
        <CreateModal
          title={modalTitle}
          description="Please use the form below to contact us and we will get back to you shortly."
          show={!!modalTitle}
          onHide={this.handleHideModal}
        />
      </div>
    );
  }
}
