import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../components/images';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { imagePropTypes } from '../../utils/prop-types';

const reasonPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: imagePropTypes().isRequired,
};

class Reason extends React.Component {
  static propTypes = {
    ...reasonPropTypes,
  };

  render() {
    const { title, description, image } = this.props;
    return (
      <div>
        <div className="h-why-icon">
          <Image description={title} {...image} />
        </div>
        <h3>{title}</h3>
        <Markdown content={description} />
      </div>
    );
  }
}

export default class why extends React.Component {
  static defaultProps = {
    items: [],
  };

  static propTypes = {
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...reasonPropTypes,
      })
    ).isRequired,
  };

  render() {
    const { heading, items } = this.props;
    return (
      <div className="h-why">
        <h2>{heading}</h2>
        <GridSection columns={2}>
          {items.map((props, index) => (
            <Reason key={index} {...props} />
          ))}
        </GridSection>
      </div>
    );
  }
}
