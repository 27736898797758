import React from 'react';

import { Container } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { Image } from '../../components/images';
import { buildFileUrl } from '../../utils/url-builders';

class SpecificationsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      specs: [
        {
          param: 'Technology',
          value: 'NIRS Diffuse Reflectance and Transmission',
        },
        { param: 'Number of Sensors', value: '2' },
        { param: 'Spot Size', value: '10mm - 13mm' },
        { param: 'Wavelength Range', value: '640 - 1050nm, 1350 - 2500nm' },
        { param: 'Resolution', value: '16 - 20nm' },
        { param: 'Battery', value: '5 Hours Continuous Use' },
        { param: 'Connectivity', value: 'Wireless BLE v4.2 1x Micro USB Port' },
        {
          param: 'Ingress Protection',
          value:
            'Designed to IP65 (Dust Tight + Water Projection) for Outdoor Use',
        },
        { param: 'Size', value: 'Handheld - 260 x 80 x 80 (mm)' },
        { param: 'Weight', value: '0.7 Kg' },
        { param: 'Mobile Application', value: 'Android™ / iOS®' },
      ],
    };
  }

  renderTableData() {
    return this.state.specs.map((spec, index) => {
      const { param, value } = spec;
      return (
        <tr key={index}>
          <td>{param}</td>
          <td>{value}</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{this.renderTableData()}</tbody>
      </table>
    );
  }
}

export default class Specifications extends React.Component {
  render() {
    const specificationImageUrl = buildFileUrl('specification-icon.svg');

    return (
      <Section className="h-hlr-specifications">
        <Container>
          <div className="h-hlr-title">
            <Image src={specificationImageUrl} />
            <h2>Specifications</h2>
          </div>
          <SpecificationsTable />
        </Container>
      </Section>
    );
  }
}
