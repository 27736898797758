import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Container, Row, Col } from 'react-bootstrap';

import Section from './section';

export default class GridSection extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    columns: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { className, columns, children, ...props } = this.props;

    // Sanitize the inputs
    let items = [];
    let factor = columns || 1;

    if (children) {
      items = Array.isArray(children) ? children : [children];
    }

    if (factor > 12) {
      factor = 12;
    } else if (factor < 1) {
      factor = 1;
    }

    return (
      <Section className={classNames('h-grid-section', className)} {...props}>
        <Container className="h-grid-section-container">
          <Row className="h-grid-section-row">
            {items.map((item, index) => (
              <Col
                key={index}
                className="h-grid-section-column"
                xs={12}
                lg={12 / factor}
              >
                {item}
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    );
  }
}
