import React from 'react';

import { Container } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { Image } from '../../components/images';
import { buildFileUrl } from '../../utils/url-builders';

export default class Technology extends React.Component {
  render() {
    const logoImageUrl = buildFileUrl('hone-logo-symbol.svg');
    const technologyImageUrl = buildFileUrl('technology.png');

    return (
      <Section className="h-hlr-technology">
        <Container>
          <div className="h-hlr-title">
            <Image src={logoImageUrl} />
            <h2>Unique Technology</h2>
          </div>
          <p>
            The device utilises Hone&apos;s unique optical configuration to
            allow rapid measurement of multiple sample types on a single device.
          </p>
          <p>
            The spectral engine is powered by two sensors covering a wide
            spectral range spanning visible and near-infrared (NIR) regions and
            includes a large measurement area for consistency with heterogenous
            samples.
          </p>
          <div className="d-flex justify-content-center">
            <Image src={technologyImageUrl} />
          </div>
        </Container>
      </Section>
    );
  }
}
