import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { BackgroundImage, Image } from '../../components/images';
import { buildFileUrl } from '../../utils/url-builders';

class Characteristic extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
  };

  render() {
    const { imageUrl, children } = this.props;
    return (
      <div className="h-avatar">
        <Image src={imageUrl} />
        <div className="h-avatar-text">{children}</div>
      </div>
    );
  }
}

export default class Characteristics extends React.Component {
  render() {
    const backgroundImageUrl = buildFileUrl('testing-bg.jpg');
    const resultsImageUrl = buildFileUrl('results-icon.svg');
    const portableImageUrl = buildFileUrl('rugged-portable-icon.svg');
    const versatileImageUrl = buildFileUrl('versatile-icon.svg');
    const secureImageUrl = buildFileUrl('secure-icon.svg');
    const australiaImageUrl = buildFileUrl('australia-icon.svg');

    return (
      <BackgroundImage
        className="h-hlr-characteristics"
        url={backgroundImageUrl}
      >
        <Section>
          <Container>
            <div className="h-avatar-group">
              <Characteristic imageUrl={resultsImageUrl}>
                Reliable Results
              </Characteristic>
              <Characteristic imageUrl={portableImageUrl}>
                Rugged and Portable
              </Characteristic>
              <Characteristic imageUrl={versatileImageUrl}>
                Versatile
              </Characteristic>
              <Characteristic imageUrl={secureImageUrl}>
                Secured Platform
              </Characteristic>
              <Characteristic imageUrl={australiaImageUrl}>
                Designed in Australia
              </Characteristic>
            </div>
          </Container>
        </Section>
      </BackgroundImage>
    );
  }
}
