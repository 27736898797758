import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { SiteConfig } from '../../components/config';
import { ExternalLink } from '../../components/links';
import { Image } from '../../components/images';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';
import { CreateModal } from '../../components/modals';

import urlBuilders from '../../utils/url-builders';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export default class HoneCreate extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  constructor(...args) {
    super(...args);

    const showModal = () =>
      this.setState({
        show: true,
      });

    const hideModal = () =>
      this.setState({
        show: false,
      });

    this.handleShowModal = showModal.bind(this);
    this.handleHideModal = hideModal.bind(this);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const { heading, content } = this.props;
    const demoImage = urlBuilders.buildFileUrl('hone-create-demo.png');

    return (
      <SiteConfig>
        {({ signupUrl, createDemoUrl }) => (
          <GridSection className="h-introduction" columns={2}>
            <div className="h-introduction-content">
              <h2>{heading}</h2>
              <Markdown content={content} />
              <Button href={signupUrl}>Sign up</Button>
              <Button onClick={this.handleShowModal}>More Info</Button>
            </div>
            <div className="h-introduction-image">
              <ExternalLink url={createDemoUrl}>
                <Image src={demoImage} srcSet={demoImage} />
                <div className="h-introduction-icon">
                  <div className="h-introduction-icon-inner">
                    <FontAwesomeIcon icon={faYoutube} />
                  </div>
                </div>
              </ExternalLink>
              <CreateModal
                title="More Info"
                description="Please use the form below to contact us and we will get back to you shortly."
                show={show}
                onHide={this.handleHideModal}
              />
            </div>
          </GridSection>
        )}
      </SiteConfig>
    );
  }
}
