import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { BackgroundImage } from '../../components/images';
import { Section } from '../../components/sections';

import { buildFileUrl } from '../../utils/url-builders';

export default class Partnerships extends React.Component {
  render() {
    const backgroundImageUrl = buildFileUrl('hone-liquid-partnerships.png');
    return (
      <div className="h-partnerships">
        <Section>
          <Container>
            <Row>
              <Col className="h-partnerships-content" lg={{ offset: 6 }}>
                <h2>Working with industry partners</h2>
                <p>
                  Hone enjoy contributing and being active within the food and
                  commodity processing sectors, working with leading industry
                  bodies and nationally-accredited research laboratories.
                </p>
                <p>
                  Formerly known as Rapid Phenotyping, Hone won the 2018 WISA
                  Impact Award for their vision of testing solutions for the
                  wine industry that has now come to fruition with the Hone
                  platform.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
        <BackgroundImage
          className="h-partnerships-image"
          url={backgroundImageUrl}
        />
      </div>
    );
  }
}
