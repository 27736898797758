import React from 'react';

import { Container } from 'react-bootstrap';
import { Image } from '../../components/images';
import { Section } from '../../components/sections';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { buildFileUrl } from '../../utils/url-builders';

export default class Technology extends React.Component {
  render() {
    const image = buildFileUrl('hone-liquid-technology.png');
    const instrumentImage = buildFileUrl(
      'hone-liquid-technology-instrument.png'
    );
    const mlImage = buildFileUrl('hone-liquid-technology-ml.png');
    const appsImage = buildFileUrl('hone-liquid-technology-apps.png');

    return (
      <Section className="h-technology">
        <h2>Our Technology</h2>
        <Container className="h-technology-image">
          <Image src={image} srcSet={image} />
        </Container>
        <GridSection columns={3}>
          <div className="h-technology-container">
            <Image src={instrumentImage} srcSet={instrumentImage} />
            <Markdown content="Next generation spectroscopy works with custom made software to collect spectral data." />
          </div>
          <div className="h-technology-container">
            <Image src={mlImage} srcSet={mlImage} />
            <Markdown content="Cloud-based machine learning platform converts spectral data into actionable testing results." />
          </div>
          <div className="h-technology-container">
            <Image src={appsImage} srcSet={appsImage} />
            <Markdown content="Real-time results delivered via the Hone suite of applications." />
          </div>
        </GridSection>
      </Section>
    );
  }
}
