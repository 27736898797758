import React from 'react';
import PropTypes from 'prop-types';

import { GridSection } from '../../components/sections';
import { BackgroundImage, Image } from '../../components/images';
import { buildFileUrl } from '../../utils/url-builders';

class FarmIcon extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
  };

  render() {
    const { imageUrl, children } = this.props;
    return (
      <div className="h-farm-icon">
        <Image src={imageUrl} />
        <div className="h-farm-icon-text">{children}</div>
      </div>
    );
  }
}

export default class Farm extends React.Component {
  render() {
    const wheatImageUrl = buildFileUrl('wheat-icon.svg');
    const leafImageUrl = buildFileUrl('leaf-grey-icon.svg');
    const soilImageUrl = buildFileUrl('soil-icon.svg');
    const farmImageUrl = buildFileUrl('on-farm-bg.png');

    return (
      <div className="h-hlr-farm">
        <GridSection columns={2}>
          <div className="h-farm-platform">
            <div className="h-farm-icons">
              <FarmIcon imageUrl={wheatImageUrl}>GRAIN</FarmIcon>
              <div className="h-coming-soon">
                <FarmIcon imageUrl={leafImageUrl}>LEAF</FarmIcon>
                <FarmIcon imageUrl={soilImageUrl}>SOIL</FarmIcon>
              </div>
            </div>
            <div className="h-farm-title">On The Farm</div>
            <div>
              <p>
                ...to analyse your wheat, barley and canola for quality
                parameters.
              </p>
              <p>
                As the Hone platform expands, software upgrades will allow
                testing of other materials such as soil and plant tissue.
              </p>
              <p>
                Using the Hone Lab on farm gives you access to measuring your
                harvest, allowing real-time decisions to improve yields.
              </p>
            </div>
          </div>
          <div></div>
        </GridSection>
        <BackgroundImage className="h-hlr-farm-image" url={farmImageUrl} />
      </div>
    );
  }
}
