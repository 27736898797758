import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import { Anchor } from '../components/links';
import { Layout } from '../components/layout';

import Content from '../content';

import { mapSection, mapListSection } from '../utils/prop-mapping';

export default class HoneCreatePage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query HoneCreatePageQuery {
            site {
              siteMetadata {
                agPublicUrl
                liquidPublicUrl
                carbonPublicUrl
              }
            }
            page: contentfulHoneCreatePage {
              title
              introduction {
                ...SectionFragment
              }
              bottleneck {
                ...SectionFragment
              }
              solution {
                ...SectionFragment
              }
              modelling {
                ...ListSectionFragment
              }
              start {
                ...SectionFragment
              }
              features {
                ...ListSectionFragment
              }
            }
          }
        `}
        render={({ site, page }) => {
          return (
            <Layout className="h-hone-create-page" {...page}>
              <Content.HoneCreate.Introduction
                {...mapSection(page.introduction)}
              />
              <Content.HoneCreate.Bottleneck {...mapSection(page.bottleneck)} />
              <Content.HoneCreate.Solution {...mapSection(page.solution)} />
              <Content.HoneCreate.Modelling
                {...mapListSection(page.modelling)}
              />
              <Content.HoneCreate.Start {...mapSection(page.start)} />
              <Content.HoneCreate.Features {...mapListSection(page.features)} />
              <Anchor id="partners" />
              <Content.HoneCreate.Partners />
              <Content.HoneCreate.Divisions
                items={[
                  {
                    label: 'Ag',
                    url: site.siteMetadata.agPublicUrl,
                  },
                  {
                    label: 'Liquid',
                    url: site.siteMetadata.liquidPublicUrl,
                  },
                  {
                    label: 'Carbon',
                    url: site.siteMetadata.carbonPublicUrl,
                  },
                ]}
              >
                The Hone Group delivers solutions in spectroscopy for a range of
                applications. Hone Ag provides handheld devices for monitoring
                nutrients in soil, plant tissue, and commodities. Hone Liquid
                provides instruments for food and commodity processing. Hone
                Carbon enables in-field soil testing for carbon sequestration.
                The Hone Group takes pleasure in forming relationships with
                business and research partners.
              </Content.HoneCreate.Divisions>
            </Layout>
          );
        }}
      />
    );
  }
}
