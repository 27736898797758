import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../components/images';
import { GridSection } from '../../components/sections';
import { Markdown } from '../../components/markdown';

import { buildFileUrl } from '../../utils/url-builders';

const items = [
  {
    title: 'No Reagents',
    description:
      'Delivers results without the use of additional chemicals or enzymes.',
    image: buildFileUrl('hone-liquid-reagents.svg'),
  },
  {
    title: 'Quick Analysis',
    description:
      'Collects spectral data and delivers results via Hone’s cloud platform within minutes.',
    image: buildFileUrl('hone-liquid-quick-analysis.svg'),
  },
  {
    title: 'Low Volume',
    description:
      'Uses only a 3mL sample to deliver results on all testing parameters required.',
    image: buildFileUrl('hone-liquid-low-volume.svg'),
  },
];

class Feature extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  };

  render() {
    const { title, description, image } = this.props;
    return (
      <>
        <div className="h-testing-feature">
          <div className="h-testing-image">
            <Image description={title} src={image} srcSet={image} />
          </div>
          <h2>{title}</h2>
          <Markdown className="h-testing-description" content={description} />
        </div>
      </>
    );
  }
}

export default class Testing extends React.Component {
  render() {
    const backgroundImage = {
      url: buildFileUrl('hone-liquid-testing-bg.png'),
    };

    return (
      <GridSection
        className="h-testing"
        backgroundImage={backgroundImage}
        columns={3}
      >
        {items.map((props, index) => (
          <Feature key={index} {...props} />
        ))}
      </GridSection>
    );
  }
}
