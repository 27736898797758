import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Section } from '../../components/sections';

import { buildFileUrl } from '../../utils/url-builders';

export default class Contact extends React.Component {
  static propTypes = {
    onShowModal: PropTypes.func.isRequired,
  };

  render() {
    const { onShowModal } = this.props;
    const backgroundImage = { url: buildFileUrl('carbon-contact-bg.jpg') };

    return (
      <Section
        className="h-hone-carbon-contact"
        backgroundImage={backgroundImage}
      >
        <h2>Get in Touch</h2>
        <div>
          <Button onClick={onShowModal}>Find Out More</Button>
        </div>
      </Section>
    );
  }
}
