import React from 'react';
import PropTypes from 'prop-types';

import { Button, Container } from 'react-bootstrap';
import { Section } from '../../components/sections';

export default class Contact extends React.Component {
  static propTypes = {
    onShowModal: PropTypes.func.isRequired,
  };

  render() {
    const { onShowModal } = this.props;
    return (
      <>
        <Section className="h-hlr-contact">
          <Container>
            <h3>Can&apos;t find a stockist near you?</h3>
            <Button variant="primary" onClick={onShowModal}>
              Contact Us
            </Button>
          </Container>
        </Section>
      </>
    );
  }
}
