import React from 'react';

import { Container } from 'react-bootstrap';
import { Section } from '../../components/sections';
import { Image } from '../../components/images';

import { buildFileUrl } from '../../utils/url-builders';

export default class Technology extends React.Component {
  render() {
    const technologyImageUrl = buildFileUrl('technology.png');
    return (
      <Section className="h-hone-carbon-technology">
        <Container>
          <h2>Unique Technology</h2>
          <p>
            Hone Carbon uses the latest portable sensor technology in
            combination with an intuitive and user-friendly interface to enable
            in-field measurements of soil carbon.
          </p>
          <p>Knowledge now.</p>
          <div className="d-flex justify-content-center">
            <Image src={technologyImageUrl} />
          </div>
        </Container>
      </Section>
    );
  }
}
